.homepage-bgimage {
    background: url("../../public/bg0.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


img { 
  width: 100px; 
  display: block; 
  text-indent: -9999px;
  margin: 5px;
  padding: 3px;
}



.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 5.25%;
}


.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
}


